.about-me  {
    height: 100vh;
}

.about-me-container {
    text-shadow: 0 0 100px rgb(10, 215, 230);
    text-align: center;
    width: 90%;
    position: absolute;
    left: 10%;
    top: 10%;
}

.letter {
    display: inline-block;
    transition: 0.4s;
    transform: translateX(0);
    font-size: 130px;
    color: #fff;
    margin-right: 10px;
    cursor: grab;
    font-weight: 700;
}

.transparent-letter {
    display: inline-block;
    margin-right: 40px;
}

.letter:hover {
    transform: translatey(-1rem);
    background: -webkit-linear-gradient(120deg, hsl(182, 77%, 38%), hsl(221, 59%, 45%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subtitle p {
    font-size: 60px;
    letter-spacing: 7px;
    background: -webkit-linear-gradient(120deg, hsl(182, 77%, 38%), hsl(221, 59%, 45%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 100%;
    margin: 0 auto;
}

.my-photo img {
    border-radius: 50%;
    width: 400px;
    border: 4px solid #03e8f48a;
    box-shadow: 0 0 20px rgb(10, 215, 230);
    background-color: rgba(0, 0, 0, .5);
    opacity: 0.7;
    margin: 50px auto;
}

.my-photo img:hover,
.my-photo img:active {
    transition: all 0.5s;
    opacity: 1;
    transform: scale(1.2);
    box-shadow: 0 0 300px rgb(10, 215, 230);
}


/* ---------------------- CSS media queries ------------------- */


@media only screen and (max-width: 1100px) {
    .letter {
        font-size: 90px;
    }

    .subtitle p {
        font-size: 50px;
        letter-spacing: 4px;
    }

    .transparent-letter {
        margin-right: 20px;
    }
    
    .subtitle p::after {
        width: 550px;
    }
}

@media only screen and (max-width: 800px) {
    .letter {
        font-size: 80px;
    }
    
    .subtitle p {
        font-size: 40px;
    }

    .about-me-container {
        width: 100%;
        left: 0;
    }
}

@media only screen and (max-width: 650px) {
    .about-me-container {
        top: 20%;
    }

    .letter {
        font-size: 60px;
    }
    
    .subtitle p {
        font-size: 30px;
        letter-spacing: 2px;
    }
    
    .my-photo img {
        width: 300px;
    }
}

@media only screen and (max-width: 500px) {
    .letter {
        margin-right: 5px;
        font-size: 45px;
    }

    .subtitle p {
        font-size: 20px;
        font-weight: 700;
    }

    .my-photo img {
        width: 250px;
    }
}