.all-projects {
    position: relative;
    text-align: center;
    color: #fff;
    height: 100vh;
    padding: 0;
    margin: 0;
}

.all-projects h1 {
    position: absolute;
    transform: translateX(-50%);
    left: 55%;
    font-size: 60px;
    font-weight: 700;
    width: 100%;
}

.all-projects h1::after {
    content: '';
    display: block;
    margin: 5px auto;
    width: 600px;
    height: 3px;
    background-image: linear-gradient(120deg, hsl(221, 59%, 45%), hsl(182, 77%, 38%));
    border-radius: 5px;
}

.all-projects-content {
    position: relative;
    transform: translateX(-50%);
    left: 55%;
    top: 30%;
    width: 50%;
    height: 50%;
    overflow: hidden;
    border-radius: 30px;
    border: 2px solid #03e9f4;
    box-shadow: 0px 0px 170px #03e9f4;
}

.all-projects-content img {
    width: 100%;
    height: 100%;
    transition: all .7s;
}

.opacity-text {
    position: absolute;
    transform: translateX(-50%);
    top: 0px;
    left: 50%;
    opacity: 0;
    color: white;
    transition: all .7s;
    max-width: 500px;
}

.opacity-text h2 {
    font-size: 50px;
    font-weight: normal;
    margin-bottom: 20px;
}

.opacity-text p {
    font-size: 25px;
}

.more-info {
    opacity: 1;
}

.change-img {
    transform: scale(1.5);
    opacity: 0.5; 
    filter: blur(5px); 
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    transform: translateX(-50%);
    bottom: 1%;
    left: 50%;
}

.buttons button {
    background-color: transparent;
    border: none;
}

.arrow-buttons {
    background-color: #03e9f4;
    border-radius: 50%;
    font-size: 45px;
    color: black;
    cursor: pointer;
    box-shadow: 0px 0px 10px #03e9f4;
    transition: all .5s;
}

.arrow-buttons:hover {
    box-shadow: 0px 0px 30px #03e9f4;
}

.all-projects-content article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s linear;
}

article.active-slide { 
    opacity: 1;
    transform: translateX(0);
}

article.last-slide {
    transform: translateX(-100%);
}

article.next-slide {
    transform: translateX(100%);
}

/* ---------------------- CSS media queries ------------------- */

@media only screen and (max-width: 2000px) {
    .opacity-text h2 {
        font-size: 40px;
        margin-bottom: 10px;
    }
    
    .opacity-text p {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1600px) {
    .all-projects-content {
        height: 40%;
    }

    .opacity-text h2 {
        font-size: 30px;
    }
    
    .opacity-text p {
        font-size: 17px;
    }

    .more-info button {
        font-size: 25px;
    }

    .arrow-buttons {
        font-size: 40px;
    }
}

@media only screen and (max-width: 1300px) {
    .all-projects-content {
        width: 70%;
        height: 50%;
    }

    .opacity-text h2 {
        font-size: 40px;
    }
    
    .opacity-text p {
        font-size: 22px;
    }
}

@media only screen and (max-width: 1100px) {
    .opacity-text p {
        width: 500px;
    }

    .all-projects-content {
        height: 40%;
    }

    .opacity-text p {
        font-size: 19px;
    }
}

@media only screen and (max-width: 900px) {
    .all-projects-content {
        height: 35%;
    }

    .arrow-buttons {
        font-size: 30px;
    }

    .opacity-text p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 800px) {
    .all-projects-content {
        height: 32%;
        left: 50%;
        top: 40%;
    }

    .all-projects h1 {
        left: 50%;
        top: 20%;
        font-size: 27px;
    }

    .all-projects h1::after {
        width: 300px;
    }

    .opacity-text h2 {
        font-size: 30px;
    }

    .opacity-text p {
        font-size: 17px;
        width: 450px;
    }
}

@media only screen and (max-width: 660px) {
    .all-projects-content {
        width: 80%;
        height: 30%;
    }

    .opacity-text h2 {
        font-size: 30px;
        margin: 5px auto;
    }

    .opacity-text p {
        font-size: 17px;
        width: 400px;
        margin: 0;
    }
}

@media only screen and (max-width: 570px) {
    .all-projects-content {
        height: 27%;
    }

    .opacity-text p {
        font-size: 16px;
        width: 370px;
    }
}

@media only screen and (max-width: 499px) {
    .opacity-text h2 {
        font-size: 18px;
        margin: 5px;
    }

    .opacity-text p {
        font-size: 13px;
        margin: 0;
        width: 300px;
    }
}