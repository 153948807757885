nav {
    position: fixed;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    width: 10vw;
    z-index: 10;
}

nav ul {
    width: 100%;
    padding: 0;
    margin-top: 100px;
}

nav ul li {
    background: -webkit-linear-gradient(120deg, hsl(182, 77%, 38%), hsl(221, 59%, 45%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    list-style-type: none;
    font-size: 22px;
    text-align: center;
    margin-top: 30px;
    cursor: pointer;
    transition: all linear 0.3s;
}

nav ul li::after {
    content: '';
    display: block;
    margin: 5px auto;
    width: 63%;
    height: 2px;
    background-image: linear-gradient(120deg, hsl(221, 59%, 45%), hsl(182, 77%, 38%));
    border-radius: 5px;
}

nav ul li:hover {
    font-size: 25px;
}

.social-networks {
    width: 100%;
    text-align: center;
    position: absolute;
    transform: translateX(-50%);
    top: 75%;
    left: 50%;
}

.social-networks a {
    font-size: 70px;
    color: white;
    margin: 5px;
    opacity: 0.3;
    transition: all linear 0.3s;
    width: 50%;
}

.social-networks a:hover {
    opacity: 1;
    font-size: 70px;
}

/* ---------------------- CSS media queries ------------------- */

@media only screen and (max-width: 1600px)  {
    nav ul li {
        font-size: 19px;
    }

    .social-networks {
        top: 80%;
    }
    
    .social-networks a {
        font-size: 50px;
        margin: 4px;
    }
    
    .social-networks a:hover {
        font-size: 40px;
    }
}

@media only screen and (max-width: 1200px)  {
    nav ul li {
        font-size: 16px;
    }

    .social-networks {
        top: 85%;
        left: 50%;
    }
    
    .social-networks a {
        font-size: 40px;
        margin: 4px;
    }
    
    .social-networks a:hover {
        font-size: 40px;
    }
}

@media only screen and (max-width: 1000px) {
    .social-networks {
        width: 100%;
        top: 75%;
        left: 50%;
    }
    
    .social-networks a {
        font-size: 40px;
        color: white;
        margin: 5px;
        opacity: 0.3;
        transition: all linear 0.3s;
    }
    
    .social-networks a:hover {
        font-size: 42px;
    }
}

@media only screen and (max-width: 800px) {

    .burger {
        display: block;
        position: absolute;
        right: 7%;
        top: 18px;
        cursor: pointer;
    }
    
    .burger span {
        display: block;
        width: 30px;
        height: 2px;
        background-color: #eee;
        margin-bottom: 5px;
        transition: all ease-in .3s;
    }
    
    .burger.open span:nth-child(1) {
        transform: rotate(45deg) translateY(10px);
    }
    
    .burger.open span:nth-child(2) {
        width: 0;
        opacity: 0;
    }
    
    .burger.open span:nth-child(3) {
        transform: rotate(-45deg) translateY(-10px);
    }
    
    nav {
        position: fixed;
        height: 50px;
        width: 100vw;
        background-color: black;
    }
    
    nav ul {
        display: block;
        width: 100%;
        padding: 0;
        margin-top: -270px;
        background-color: black;
        transition: all linear .3s;
    }

    .open-menu {
        margin-top: 50px;
    }
    
    nav ul li {
        background: -webkit-linear-gradient(120deg, hsl(0, 0%, 68%), hsl(0, 1%, 78%));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0;
        padding: 15px;
    }
    
    nav ul li::after {
        width: 90px;
        background-image: linear-gradient(120deg, hsl(221, 59%, 45%), hsl(182, 77%, 38%));
        border-radius: 5px;
    }
    
    .social-networks {
        top: 10px;
        left: 75px;
    }
    
    .social-networks a {
        font-size: 30px;
        margin-right: 0;
    }
    
    .social-networks a:hover {
        font-size: 30px;
    }
}