body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-image: url("./images/background.jpg");
    background-attachment: fixed;
    background-position: center;
    font-family: "Ubuntu", sans-serif;
    overflow-x: hidden;
}
