.my-skills {
    height: 100vh;
    background: black;
}

.my-skills-container {
    text-align: center;
    color: white;
    font-size: 130px;
    margin: 200px auto;
    width: 90%;
    position: absolute;
    left: 10%;
}

.my-skills-container .text {
    margin-right: 40px;
    font-weight: 700;
}

.my-skills-container > div {
    display: inline-block;
    height: 150px;
    line-height: 150px;
    overflow: hidden;
}

div.word-list span {
    display: block;
    font-weight: 700;
}

div.word-list {
    position: relative;
    top: 0px;
    animation: cycleText ease 7s infinite; 
}

@keyframes cycleText {
    0% {top: 0}
   9% {top: -150px}
   18% {top: -300px}
   27% {top: -450px}
   36% {top: -600px}
   45% {top: -750px}
   54% {top: -900px}
   63% {top: -1050px}
   72% {top: -1200px}
   81% {top: -1350px}
   90% {top: -1500px}
   100% {top: -0px}
}


span:nth-child(1) {
    color: #3775A8;
}

span:nth-child(2) {
    color: #F05133;
}

span:nth-child(3) {
    color: #FFA518;
}

span:nth-child(4) {
    color: #E54D26;
}

span:nth-child(5) {
    color: #264DE4;
}

span:nth-child(6) {
    color: #17B6BC;
}

span:nth-child(7) {
    color: #F7DE1F;
}

span:nth-child(8) {
    color: #0FDBF6;
}

span:nth-child(9) {
    color: #e6e6e6;
}

span:nth-child(10) {
    color: #777BB3;
}

span:nth-child(11) {
    color: #FF2D20;
}

.skills-icons {
    font-size: 400px;
    position: absolute;
    top: 140%;
    transform: translateX(-50%);
    left: 55%;
    width: 400px;
    height: 400px;
    line-height: 0px;
    overflow: hidden;
}

.icon {
    position: relative;
    animation: cycleIcons ease 7s infinite;  
}

@keyframes cycleIcons {
    0% {top: 0}
   9% {top: -400px}
   18% {top: -800px}
   27% {top: -1200px}
   36% {top: -1600px}
   45% {top: -2000px}
   54% {top: -2400px}
   63% {top: -2800px}
   72% {top: -3200px}
   81% {top: -3600px}
   90% {top: -4000px}
   100% {top: 0}
}


.skills-icons .python-icon {
    color: #3775A8;
}

.skills-icons .git-icon {
    color: #F05133;
}

.skills-icons .mysql-icon {
    color: #FFA518;
}

.skills-icons .html-icon {
    color: #E54D26;
}

.skills-icons .css-icon {
    color: #264DE4;
}

.skills-icons .tailwind-icon {
    color: #17B6BC;
}

.skills-icons .js-icon {
    color: #F7DE1F;
}

.skills-icons .react-icon {
    color: #0FDBF6;
}

.skills-icons .nextjs-icon {
    color: #e6e6e6;
}

.skills-icons .php-icon {
    color: #777BB3;
}

.skills-icons .laravel-icon {
    color: #FF2D20;
}



/* ---------------------- CSS media queries ------------------- */

@media only screen and (max-width: 1300px) {
    .my-skills-container {
        font-size: 100px;
    }
}

@media only screen and (max-width: 1000px) {
    .my-skills-container {
        font-size: 80px;
    }
    
    .my-skills-container .text {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 800px) {
    .my-skills-container {
        font-size: 65px;
    }

    .skills-icons {
        font-size: 200px;
        width: 200px;
        height: 200px;
        transform: translateX(-50%);
        left: 50%;
        top: 160%;
        line-height: 0px;
    }

    @keyframes cycleIcons {
        0% {top: 0}
       9% {top: -200px}
       18% {top: -400px}
       27% {top: -600px}
       36% {top: -800px}
       45% {top: -1000px}
       54% {top: -1200px}
       63% {top: -1400px}
       72% {top: -1600px}
       81% {top: -1800px}
       90% {top: -2000px}
       100% {top: 0}
    }
}

@media only screen and (max-width: 600px) {
    .my-skills-container {
        font-size: 50px;
    }
}

@media only screen and (max-width: 500px) {
    .my-skills-container {
        font-size: 30px;
    }
}